import React from 'react'
import '../styles/css/preloader.css'

const Preloader = () => {
  return (
    <div className="thepreloader">
      <svg id="introplayer" width="780" height="122" viewBox="0 0 780 122" fill="white" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-outside-1_3_2" maskUnits="userSpaceOnUse" x="0.368011" y="0.272003" width="780" height="122" fill="black">
            <rect id="innerfill" x="0.368011" y="0.272003" width="780" height="122"/>
            <path d="M91.032 62.872C91.032 72.952 89.304 81.928 85.848 89.8C82.488 97.576 77.304 103.72 70.296 108.232C63.288 112.744 54.36 115 43.512 115H9.38401V19.672C9.38401 19.576 9.19201 18.808 8.80801 17.368C8.52001 15.928 8.23201 14.44 7.94401 12.904C7.65601 11.368 7.46401 10.456 7.36801 10.168H34.296C43.032 10.168 50.904 11.176 57.912 13.192C64.92 15.112 70.872 18.184 75.768 22.408C80.76 26.536 84.552 31.96 87.144 38.68C89.736 45.304 91.032 53.368 91.032 62.872ZM77.064 62.584C77.064 55 76.104 48.52 74.184 43.144C72.36 37.768 69.576 33.4 65.832 30.04C62.184 26.584 57.624 24.088 52.152 22.552C46.776 20.92 40.488 20.104 33.288 20.104H22.776V105.064H41.784C50.52 105.064 57.432 103.144 62.52 99.304C67.704 95.464 71.4 90.328 73.608 83.896C75.912 77.464 77.064 70.36 77.064 62.584Z"/>
            <path d="M136.568 41.704C142.328 41.704 147.176 42.904 151.112 45.304C155.048 47.608 157.976 50.872 159.896 55.096C161.912 59.224 162.92 64.072 162.92 69.64C162.92 70.984 162.824 72.376 162.632 73.816C162.536 75.16 162.392 76.6 162.2 78.136H116.696C116.696 87.256 118.712 94.312 122.744 99.304C126.776 104.296 132.152 106.792 138.872 106.792C141.656 106.792 144.536 106.36 147.512 105.496C150.584 104.632 153.752 103.336 157.016 101.608L161.48 108.808C158.12 111.496 154.328 113.512 150.104 114.856C145.88 116.2 141.608 116.872 137.288 116.872C131.624 116.872 126.68 115.864 122.456 113.848C118.328 111.736 114.872 108.952 112.088 105.496C109.4 101.944 107.384 97.96 106.04 93.544C104.696 89.128 104.024 84.616 104.024 80.008C104.024 75.208 104.696 70.552 106.04 66.04C107.384 61.528 109.4 57.448 112.088 53.8C114.872 50.056 118.28 47.128 122.312 45.016C126.344 42.808 131.096 41.704 136.568 41.704ZM135.416 50.776C132.152 50.776 129.272 51.688 126.776 53.512C124.28 55.336 122.264 57.688 120.728 60.568C119.192 63.352 118.136 66.232 117.56 69.208H150.392C150.488 65.176 149.912 61.816 148.664 59.128C147.512 56.344 145.784 54.28 143.48 52.936C141.272 51.496 138.584 50.776 135.416 50.776Z"/>
            <path d="M188.716 42.28C189.388 43.912 189.964 45.592 190.444 47.32C190.924 49.048 191.26 50.776 191.452 52.504C194.332 49.624 197.74 47.128 201.676 45.016C205.612 42.808 209.644 41.704 213.772 41.704C219.052 41.704 223.276 42.808 226.444 45.016C229.708 47.224 232.06 50.248 233.5 54.088C234.94 57.832 235.66 62.2 235.66 67.192V115H223.852V71.08C223.852 68.104 223.612 65.176 223.132 62.296C222.748 59.416 221.692 57.064 219.964 55.24C218.332 53.32 215.596 52.36 211.756 52.36C209.452 52.36 207.1 52.84 204.7 53.8C202.3 54.664 200.044 55.816 197.932 57.256C195.82 58.696 193.9 60.232 192.172 61.864V115H180.364V64.312C180.364 62.392 180.22 60.184 179.932 57.688C179.644 55.096 179.164 52.6 178.492 50.2C177.82 47.704 176.86 45.64 175.612 44.008L188.716 42.28Z"/>
            <path d="M267.466 42.28C268.138 43.912 268.714 45.592 269.194 47.32C269.674 49.048 270.01 50.776 270.202 52.504C273.082 49.624 276.49 47.128 280.426 45.016C284.362 42.808 288.394 41.704 292.522 41.704C297.802 41.704 302.026 42.808 305.194 45.016C308.458 47.224 310.81 50.248 312.25 54.088C313.69 57.832 314.41 62.2 314.41 67.192V115H302.602V71.08C302.602 68.104 302.362 65.176 301.882 62.296C301.498 59.416 300.442 57.064 298.714 55.24C297.082 53.32 294.346 52.36 290.506 52.36C288.202 52.36 285.85 52.84 283.45 53.8C281.05 54.664 278.794 55.816 276.682 57.256C274.57 58.696 272.65 60.232 270.922 61.864V115H259.114V64.312C259.114 62.392 258.97 60.184 258.682 57.688C258.394 55.096 257.914 52.6 257.242 50.2C256.57 47.704 255.61 45.64 254.362 44.008L267.466 42.28Z"/>
            <path d="M352.984 19.384C352.984 21.784 352.12 23.848 350.392 25.576C348.76 27.304 346.696 28.168 344.2 28.168C341.8 28.168 339.736 27.304 338.008 25.576C336.376 23.848 335.56 21.784 335.56 19.384C335.56 16.984 336.376 14.92 338.008 13.192C339.736 11.464 341.8 10.6 344.2 10.6C346.6 10.6 348.664 11.464 350.392 13.192C352.12 14.92 352.984 16.984 352.984 19.384ZM350.392 43.432V115H338.44V51.928L336.712 43.432H350.392Z"/>
            <path d="M397.62 41.704C400.884 41.704 404.1 42.136 407.268 43C410.532 43.768 413.508 45.016 416.196 46.744L412.02 55.96C409.908 54.232 407.556 52.936 404.964 52.072C402.372 51.208 399.828 50.776 397.332 50.776C393.78 50.776 390.66 51.592 387.972 53.224C385.38 54.856 384.084 57.304 384.084 60.568C384.084 62.872 384.612 64.792 385.668 66.328C386.82 67.864 388.308 69.16 390.132 70.216C391.956 71.272 393.924 72.328 396.036 73.384C398.436 74.536 400.836 75.736 403.236 76.984C405.732 78.136 407.988 79.576 410.004 81.304C412.116 82.936 413.796 84.952 415.044 87.352C416.388 89.656 417.06 92.536 417.06 95.992C417.06 100.792 415.86 104.776 413.46 107.944C411.156 111.016 408.084 113.272 404.244 114.712C400.5 116.152 396.42 116.872 392.004 116.872C388.164 116.872 384.324 116.344 380.484 115.288C376.644 114.232 373.092 112.744 369.828 110.824L374.868 102.04C377.172 103.768 379.764 105.16 382.644 106.216C385.524 107.272 388.308 107.8 390.996 107.8C394.644 107.8 397.812 106.936 400.5 105.208C403.284 103.48 404.676 100.888 404.676 97.432C404.676 95.032 403.908 93.064 402.372 91.528C400.932 89.896 399.06 88.456 396.756 87.208C394.452 85.96 392.052 84.76 389.556 83.608C386.676 82.168 383.844 80.632 381.06 79C378.372 77.272 376.164 75.112 374.436 72.52C372.708 69.928 371.844 66.616 371.844 62.584C371.844 57.976 373.044 54.136 375.444 51.064C377.94 47.992 381.156 45.688 385.092 44.152C389.028 42.52 393.204 41.704 397.62 41.704Z"/>
            <path d="M467.915 10.168H483.323V56.968L524.939 10.168H538.907L497.579 56.824L541.499 115H524.795L483.323 60.424V115H469.931V19.672L467.915 10.168Z"/>
            <path d="M568.14 19.384C568.14 21.784 567.276 23.848 565.548 25.576C563.916 27.304 561.852 28.168 559.356 28.168C556.956 28.168 554.892 27.304 553.164 25.576C551.532 23.848 550.716 21.784 550.716 19.384C550.716 16.984 551.532 14.92 553.164 13.192C554.892 11.464 556.956 10.6 559.356 10.6C561.756 10.6 563.82 11.464 565.548 13.192C567.276 14.92 568.14 16.984 568.14 19.384ZM565.548 43.432V115H553.596V51.928L551.868 43.432H565.548Z"/>
            <path d="M621.561 41.992C626.553 41.992 630.921 43.048 634.665 45.16C638.409 47.272 641.481 50.152 643.881 53.8C646.377 57.352 648.249 61.288 649.497 65.608C650.745 69.928 651.369 74.344 651.369 78.856C651.369 83.752 650.697 88.456 649.353 92.968C648.105 97.48 646.185 101.56 643.593 105.208C641.001 108.76 637.737 111.592 633.801 113.704C629.865 115.816 625.209 116.872 619.833 116.872C615.801 116.872 611.913 116.104 608.169 114.568C604.521 113.032 601.401 110.728 598.809 107.656L596.217 115.144H589.017C589.113 113.512 589.209 111.88 589.305 110.248C589.401 108.52 589.449 106.84 589.449 105.208V13.624L587.433 5.272H601.401V45.016C601.401 45.88 601.353 46.744 601.257 47.608C601.257 48.376 601.209 49.192 601.113 50.056C604.185 47.272 607.305 45.256 610.473 44.008C613.737 42.664 617.433 41.992 621.561 41.992ZM618.537 52.216C615.369 52.216 612.249 52.984 609.177 54.52C606.201 55.96 603.609 57.832 601.401 60.136V86.344C601.401 90.376 601.977 94.024 603.129 97.288C604.281 100.552 606.153 103.144 608.745 105.064C611.433 106.984 614.985 107.944 619.401 107.944C623.049 107.944 626.073 107.032 628.473 105.208C630.969 103.288 632.937 100.888 634.377 98.008C635.817 95.032 636.873 91.912 637.545 88.648C638.217 85.288 638.553 82.168 638.553 79.288C638.553 76.12 638.169 72.952 637.401 69.784C636.729 66.616 635.577 63.736 633.945 61.144C632.409 58.456 630.345 56.296 627.753 54.664C625.257 53.032 622.185 52.216 618.537 52.216Z"/>
            <path d="M696.818 41.704C702.578 41.704 707.426 42.904 711.362 45.304C715.298 47.608 718.226 50.872 720.146 55.096C722.162 59.224 723.17 64.072 723.17 69.64C723.17 70.984 723.074 72.376 722.882 73.816C722.786 75.16 722.642 76.6 722.45 78.136H676.946C676.946 87.256 678.962 94.312 682.994 99.304C687.026 104.296 692.402 106.792 699.122 106.792C701.906 106.792 704.786 106.36 707.762 105.496C710.834 104.632 714.002 103.336 717.266 101.608L721.73 108.808C718.37 111.496 714.578 113.512 710.354 114.856C706.13 116.2 701.858 116.872 697.538 116.872C691.874 116.872 686.93 115.864 682.706 113.848C678.578 111.736 675.122 108.952 672.338 105.496C669.65 101.944 667.634 97.96 666.29 93.544C664.946 89.128 664.274 84.616 664.274 80.008C664.274 75.208 664.946 70.552 666.29 66.04C667.634 61.528 669.65 57.448 672.338 53.8C675.122 50.056 678.53 47.128 682.562 45.016C686.594 42.808 691.346 41.704 696.818 41.704ZM695.666 50.776C692.402 50.776 689.522 51.688 687.026 53.512C684.53 55.336 682.514 57.688 680.978 60.568C679.442 63.352 678.386 66.232 677.81 69.208H710.642C710.738 65.176 710.162 61.816 708.914 59.128C707.762 56.344 706.034 54.28 703.73 52.936C701.522 51.496 698.834 50.776 695.666 50.776Z"/>
            <path d="M757.462 22.984C756.79 26.248 756.166 29.656 755.59 33.208C755.11 36.664 754.774 40.072 754.582 43.432H773.302L770.854 52.504H754.582V98.296C754.582 100.12 754.774 101.752 755.158 103.192C755.638 104.536 756.406 105.592 757.462 106.36C758.614 107.032 760.198 107.368 762.214 107.368C763.75 107.368 765.286 107.224 766.822 106.936C768.358 106.648 769.846 106.312 771.286 105.928L773.302 113.992C770.902 114.664 768.598 115.24 766.39 115.72C764.278 116.296 761.83 116.584 759.046 116.584C752.902 116.584 748.63 114.952 746.23 111.688C743.83 108.424 742.63 103.864 742.63 98.008V52.504H731.974V45.304C734.854 45.208 737.254 44.44 739.174 43C741.094 41.56 742.678 39.736 743.926 37.528C745.174 35.224 746.182 32.824 746.95 30.328C747.718 27.736 748.294 25.288 748.678 22.984H757.462Z"/>
        </mask>
        <path fill="white" id="introTry" d="M91.032 62.872C91.032 72.952 89.304 81.928 85.848 89.8C82.488 97.576 77.304 103.72 70.296 108.232C63.288 112.744 54.36 115 43.512 115H9.38401V19.672C9.38401 19.576 9.19201 18.808 8.80801 17.368C8.52001 15.928 8.23201 14.44 7.94401 12.904C7.65601 11.368 7.46401 10.456 7.36801 10.168H34.296C43.032 10.168 50.904 11.176 57.912 13.192C64.92 15.112 70.872 18.184 75.768 22.408C80.76 26.536 84.552 31.96 87.144 38.68C89.736 45.304 91.032 53.368 91.032 62.872ZM77.064 62.584C77.064 55 76.104 48.52 74.184 43.144C72.36 37.768 69.576 33.4 65.832 30.04C62.184 26.584 57.624 24.088 52.152 22.552C46.776 20.92 40.488 20.104 33.288 20.104H22.776V105.064H41.784C50.52 105.064 57.432 103.144 62.52 99.304C67.704 95.464 71.4 90.328 73.608 83.896C75.912 77.464 77.064 70.36 77.064 62.584Z" stroke="white" strokeWidth="10" mask="url(#path-1-outside-1_3_2)"/>
        <path id="introTry" d="M136.568 41.704C142.328 41.704 147.176 42.904 151.112 45.304C155.048 47.608 157.976 50.872 159.896 55.096C161.912 59.224 162.92 64.072 162.92 69.64C162.92 70.984 162.824 72.376 162.632 73.816C162.536 75.16 162.392 76.6 162.2 78.136H116.696C116.696 87.256 118.712 94.312 122.744 99.304C126.776 104.296 132.152 106.792 138.872 106.792C141.656 106.792 144.536 106.36 147.512 105.496C150.584 104.632 153.752 103.336 157.016 101.608L161.48 108.808C158.12 111.496 154.328 113.512 150.104 114.856C145.88 116.2 141.608 116.872 137.288 116.872C131.624 116.872 126.68 115.864 122.456 113.848C118.328 111.736 114.872 108.952 112.088 105.496C109.4 101.944 107.384 97.96 106.04 93.544C104.696 89.128 104.024 84.616 104.024 80.008C104.024 75.208 104.696 70.552 106.04 66.04C107.384 61.528 109.4 57.448 112.088 53.8C114.872 50.056 118.28 47.128 122.312 45.016C126.344 42.808 131.096 41.704 136.568 41.704ZM135.416 50.776C132.152 50.776 129.272 51.688 126.776 53.512C124.28 55.336 122.264 57.688 120.728 60.568C119.192 63.352 118.136 66.232 117.56 69.208H150.392C150.488 65.176 149.912 61.816 148.664 59.128C147.512 56.344 145.784 54.28 143.48 52.936C141.272 51.496 138.584 50.776 135.416 50.776Z" stroke="white" strokeWidth="10" mask="url(#path-1-outside-1_3_2)"/>
        <path id="introTry" d="M188.716 42.28C189.388 43.912 189.964 45.592 190.444 47.32C190.924 49.048 191.26 50.776 191.452 52.504C194.332 49.624 197.74 47.128 201.676 45.016C205.612 42.808 209.644 41.704 213.772 41.704C219.052 41.704 223.276 42.808 226.444 45.016C229.708 47.224 232.06 50.248 233.5 54.088C234.94 57.832 235.66 62.2 235.66 67.192V115H223.852V71.08C223.852 68.104 223.612 65.176 223.132 62.296C222.748 59.416 221.692 57.064 219.964 55.24C218.332 53.32 215.596 52.36 211.756 52.36C209.452 52.36 207.1 52.84 204.7 53.8C202.3 54.664 200.044 55.816 197.932 57.256C195.82 58.696 193.9 60.232 192.172 61.864V115H180.364V64.312C180.364 62.392 180.22 60.184 179.932 57.688C179.644 55.096 179.164 52.6 178.492 50.2C177.82 47.704 176.86 45.64 175.612 44.008L188.716 42.28Z" stroke="white" strokeWidth="10" mask="url(#path-1-outside-1_3_2)"/>
        <path id="introTry" d="M267.466 42.28C268.138 43.912 268.714 45.592 269.194 47.32C269.674 49.048 270.01 50.776 270.202 52.504C273.082 49.624 276.49 47.128 280.426 45.016C284.362 42.808 288.394 41.704 292.522 41.704C297.802 41.704 302.026 42.808 305.194 45.016C308.458 47.224 310.81 50.248 312.25 54.088C313.69 57.832 314.41 62.2 314.41 67.192V115H302.602V71.08C302.602 68.104 302.362 65.176 301.882 62.296C301.498 59.416 300.442 57.064 298.714 55.24C297.082 53.32 294.346 52.36 290.506 52.36C288.202 52.36 285.85 52.84 283.45 53.8C281.05 54.664 278.794 55.816 276.682 57.256C274.57 58.696 272.65 60.232 270.922 61.864V115H259.114V64.312C259.114 62.392 258.97 60.184 258.682 57.688C258.394 55.096 257.914 52.6 257.242 50.2C256.57 47.704 255.61 45.64 254.362 44.008L267.466 42.28Z" stroke="white" strokeWidth="10" mask="url(#path-1-outside-1_3_2)"/>
        <path id="introTry" d="M352.984 19.384C352.984 21.784 352.12 23.848 350.392 25.576C348.76 27.304 346.696 28.168 344.2 28.168C341.8 28.168 339.736 27.304 338.008 25.576C336.376 23.848 335.56 21.784 335.56 19.384C335.56 16.984 336.376 14.92 338.008 13.192C339.736 11.464 341.8 10.6 344.2 10.6C346.6 10.6 348.664 11.464 350.392 13.192C352.12 14.92 352.984 16.984 352.984 19.384ZM350.392 43.432V115H338.44V51.928L336.712 43.432H350.392Z" stroke="white" strokeWidth="10" mask="url(#path-1-outside-1_3_2)"/>
        <path id="introTry" d="M397.62 41.704C400.884 41.704 404.1 42.136 407.268 43C410.532 43.768 413.508 45.016 416.196 46.744L412.02 55.96C409.908 54.232 407.556 52.936 404.964 52.072C402.372 51.208 399.828 50.776 397.332 50.776C393.78 50.776 390.66 51.592 387.972 53.224C385.38 54.856 384.084 57.304 384.084 60.568C384.084 62.872 384.612 64.792 385.668 66.328C386.82 67.864 388.308 69.16 390.132 70.216C391.956 71.272 393.924 72.328 396.036 73.384C398.436 74.536 400.836 75.736 403.236 76.984C405.732 78.136 407.988 79.576 410.004 81.304C412.116 82.936 413.796 84.952 415.044 87.352C416.388 89.656 417.06 92.536 417.06 95.992C417.06 100.792 415.86 104.776 413.46 107.944C411.156 111.016 408.084 113.272 404.244 114.712C400.5 116.152 396.42 116.872 392.004 116.872C388.164 116.872 384.324 116.344 380.484 115.288C376.644 114.232 373.092 112.744 369.828 110.824L374.868 102.04C377.172 103.768 379.764 105.16 382.644 106.216C385.524 107.272 388.308 107.8 390.996 107.8C394.644 107.8 397.812 106.936 400.5 105.208C403.284 103.48 404.676 100.888 404.676 97.432C404.676 95.032 403.908 93.064 402.372 91.528C400.932 89.896 399.06 88.456 396.756 87.208C394.452 85.96 392.052 84.76 389.556 83.608C386.676 82.168 383.844 80.632 381.06 79C378.372 77.272 376.164 75.112 374.436 72.52C372.708 69.928 371.844 66.616 371.844 62.584C371.844 57.976 373.044 54.136 375.444 51.064C377.94 47.992 381.156 45.688 385.092 44.152C389.028 42.52 393.204 41.704 397.62 41.704Z" stroke="white" strokeWidth="10" mask="url(#path-1-outside-1_3_2)"/>
        <path id="introTry" d="M467.915 10.168H483.323V56.968L524.939 10.168H538.907L497.579 56.824L541.499 115H524.795L483.323 60.424V115H469.931V19.672L467.915 10.168Z" stroke="white" strokeWidth="10" mask="url(#path-1-outside-1_3_2)"/>
        <path id="introTry" d="M568.14 19.384C568.14 21.784 567.276 23.848 565.548 25.576C563.916 27.304 561.852 28.168 559.356 28.168C556.956 28.168 554.892 27.304 553.164 25.576C551.532 23.848 550.716 21.784 550.716 19.384C550.716 16.984 551.532 14.92 553.164 13.192C554.892 11.464 556.956 10.6 559.356 10.6C561.756 10.6 563.82 11.464 565.548 13.192C567.276 14.92 568.14 16.984 568.14 19.384ZM565.548 43.432V115H553.596V51.928L551.868 43.432H565.548Z" stroke="white" strokeWidth="10" mask="url(#path-1-outside-1_3_2)"/>
        <path id="introTry" d="M621.561 41.992C626.553 41.992 630.921 43.048 634.665 45.16C638.409 47.272 641.481 50.152 643.881 53.8C646.377 57.352 648.249 61.288 649.497 65.608C650.745 69.928 651.369 74.344 651.369 78.856C651.369 83.752 650.697 88.456 649.353 92.968C648.105 97.48 646.185 101.56 643.593 105.208C641.001 108.76 637.737 111.592 633.801 113.704C629.865 115.816 625.209 116.872 619.833 116.872C615.801 116.872 611.913 116.104 608.169 114.568C604.521 113.032 601.401 110.728 598.809 107.656L596.217 115.144H589.017C589.113 113.512 589.209 111.88 589.305 110.248C589.401 108.52 589.449 106.84 589.449 105.208V13.624L587.433 5.272H601.401V45.016C601.401 45.88 601.353 46.744 601.257 47.608C601.257 48.376 601.209 49.192 601.113 50.056C604.185 47.272 607.305 45.256 610.473 44.008C613.737 42.664 617.433 41.992 621.561 41.992ZM618.537 52.216C615.369 52.216 612.249 52.984 609.177 54.52C606.201 55.96 603.609 57.832 601.401 60.136V86.344C601.401 90.376 601.977 94.024 603.129 97.288C604.281 100.552 606.153 103.144 608.745 105.064C611.433 106.984 614.985 107.944 619.401 107.944C623.049 107.944 626.073 107.032 628.473 105.208C630.969 103.288 632.937 100.888 634.377 98.008C635.817 95.032 636.873 91.912 637.545 88.648C638.217 85.288 638.553 82.168 638.553 79.288C638.553 76.12 638.169 72.952 637.401 69.784C636.729 66.616 635.577 63.736 633.945 61.144C632.409 58.456 630.345 56.296 627.753 54.664C625.257 53.032 622.185 52.216 618.537 52.216Z" stroke="white" strokeWidth="10" mask="url(#path-1-outside-1_3_2)"/>
        <path id="introTry" d="M696.818 41.704C702.578 41.704 707.426 42.904 711.362 45.304C715.298 47.608 718.226 50.872 720.146 55.096C722.162 59.224 723.17 64.072 723.17 69.64C723.17 70.984 723.074 72.376 722.882 73.816C722.786 75.16 722.642 76.6 722.45 78.136H676.946C676.946 87.256 678.962 94.312 682.994 99.304C687.026 104.296 692.402 106.792 699.122 106.792C701.906 106.792 704.786 106.36 707.762 105.496C710.834 104.632 714.002 103.336 717.266 101.608L721.73 108.808C718.37 111.496 714.578 113.512 710.354 114.856C706.13 116.2 701.858 116.872 697.538 116.872C691.874 116.872 686.93 115.864 682.706 113.848C678.578 111.736 675.122 108.952 672.338 105.496C669.65 101.944 667.634 97.96 666.29 93.544C664.946 89.128 664.274 84.616 664.274 80.008C664.274 75.208 664.946 70.552 666.29 66.04C667.634 61.528 669.65 57.448 672.338 53.8C675.122 50.056 678.53 47.128 682.562 45.016C686.594 42.808 691.346 41.704 696.818 41.704ZM695.666 50.776C692.402 50.776 689.522 51.688 687.026 53.512C684.53 55.336 682.514 57.688 680.978 60.568C679.442 63.352 678.386 66.232 677.81 69.208H710.642C710.738 65.176 710.162 61.816 708.914 59.128C707.762 56.344 706.034 54.28 703.73 52.936C701.522 51.496 698.834 50.776 695.666 50.776Z" stroke="white" strokeWidth="10" mask="url(#path-1-outside-1_3_2)"/>
        <path id="introTry" d="M757.462 22.984C756.79 26.248 756.166 29.656 755.59 33.208C755.11 36.664 754.774 40.072 754.582 43.432H773.302L770.854 52.504H754.582V98.296C754.582 100.12 754.774 101.752 755.158 103.192C755.638 104.536 756.406 105.592 757.462 106.36C758.614 107.032 760.198 107.368 762.214 107.368C763.75 107.368 765.286 107.224 766.822 106.936C768.358 106.648 769.846 106.312 771.286 105.928L773.302 113.992C770.902 114.664 768.598 115.24 766.39 115.72C764.278 116.296 761.83 116.584 759.046 116.584C752.902 116.584 748.63 114.952 746.23 111.688C743.83 108.424 742.63 103.864 742.63 98.008V52.504H731.974V45.304C734.854 45.208 737.254 44.44 739.174 43C741.094 41.56 742.678 39.736 743.926 37.528C745.174 35.224 746.182 32.824 746.95 30.328C747.718 27.736 748.294 25.288 748.678 22.984H757.462Z" stroke="white" strokeWidth="10" mask="url(#path-1-outside-1_3_2)"/>
    </svg>
    </div>
  )
}

export default Preloader