import React, { Fragment } from 'react'
import '../styles/css/header.css'

const Header = () => {
  return (
    <Fragment>
        <div className="adminheader">
          <div className="adminHContainer">
            <h1>Dashboard</h1>
          </div>
        </div>
    </Fragment>
  )
}

export default Header